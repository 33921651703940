import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
//import { faPhp } from '@fortawesome/free-brands-svg-icons';
import { faShoppingCart, faListAlt, faKeyboard } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  faListAlt = faListAlt;
  //faPhp = faPhp;
  faShoppingCart = faShoppingCart;
  faKeyboard = faKeyboard;

  constructor(public meta: Meta, public title: Title) {
  }

  ngOnInit() {
    const pageTitle: string = 'Sell Digital Downloads and Physical Items with eCommerce PHP Shopping Cart';

    this.meta.addTag({
      name: 'description',
      content: 'Sell physical items and sell digital downloads using an ecommerce PHP shopping cart on your web site with PayPal'
    });
    this.title.setTitle(pageTitle);
  }

 
}
