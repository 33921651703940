import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { FeaturesComponent } from './features/features.component';
import { RequirementsComponent } from './requirements/requirements.component';
import { LayoutsComponent } from './layouts/layouts.component';
import { PricingComponent } from './pricing/pricing.component';


const routes: Routes = [

  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'pricing', component: PricingComponent },  
  { path: 'features', component: FeaturesComponent },
  { path: 'requirements', component: RequirementsComponent },
  { path: 'layouts', component: LayoutsComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled", initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
