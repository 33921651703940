<div class="container marketing">

  <div class="row">
    <div class="col-lg-2"></div>
    <div class="col-lg-8">
      <h1 style="text-align:center;">PHP-eSeller<br />Features</h1>
    </div>
    <div class="col-lg-2"></div>
  </div>

  <hr class="featurette-divider">


  <div class="row">
    <div class="col-lg-2">&nbsp;</div><!-- /.col-lg-2 -->
    <div class="col-lg-8">    
      <div class="lead">
        <ul>
          <li>Interacts with Paypal's IPN system to monitor when a payment is complete for both digital downloads and physical goods.</li>
          <li>Automatically generates a login in url link for PayPal purchases to download their digital goods.</li>
          <li>The purchaser has a defined time limit to download their files and has a defined number of retries.</li>
          <li>Files are NOT accessible via a normal href link and hence cannot be downloaded by unauthorized users.</li>
          <li>The PHP shopping carts are fully responsive so that they can be displayed on any mobile device and are based on Twitter Bootstrap.</li>
          <li>Audio clips use HTML 5 elements so will work on all modern browsers as well as mobile devices, tablets and phones.</li>
          <li>Physical and digital items may be added to the cart at the same time.</li>
          <li>Shipping calculations (flat amount or destination based for international postage) can be added to physical goods.</li>
          <li>You may place buy now, and add to cart buttons by hand onto existing web pages, or use the template driven shopping store.</li>
          <li>Includes a test page to check the basic operation of the scripts.</li>
          <li>Incorporates testing via the PayPal environment so that the complete system, can be tested.</li>
          <li>Includes a facility to send items to multiple customers which may be used for upgrading software or complementary sells.</li>
          <li>All pages written in PHP and script is NOT encrypted.</li>
          <li>All credit card transactions are handled by PayPal.</li>
        </ul>

      </div>
    </div><!-- /.col-lg-8 -->
    <div class="col-lg-2">&nbsp;</div><!-- /.col-lg-2 -->
  </div><!-- /.row -->


</div>
