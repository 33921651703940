
<!-- Marketing messaging and featurettes
 ================================================== -->
<!-- Wrap the rest of the page in another container to center all the content. -->

  <div class="container marketing">

    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-8">
        <h1 style="text-align:center;">PHP-eSeller<br />eCommerce PHP Shopping Cart<br />from <a href="https://www.withinweb.com/phpeseller/" target="_blank">WithinWeb.com</a></h1>
      </div>
      <div class="col-lg-2"></div>
    </div>

    <hr class="featurette-divider">

    <div class="row row-eq-height">
      <div class="col-lg-4">

        <fa-icon [icon]="faShoppingCart" size="4x" style="color:lightgray"></fa-icon>

        <h2>Sell digital and physical goods</h2>
        <p><strong>PHP-eSeller</strong> is a shopping cart in PHP for selling digital downloads and physical (tangible) products using PayPal as the payment processor.</p>
        <p>
          Digital downloads that can be sold includes software programs, mp3 files, graphics, icons, photos, ring tones, ebooks and videos and anything else that is a digital file.
        </p>
        <p><a class="btn btn-secondary" routerLink="/features" role="button">Features &raquo;</a></p>
      </div><!-- /.col-lg-4 -->
      <div class="col-lg-4">

        <fa-icon [icon]="faKeyboard" size="4x" style="color:lightgray"></fa-icon>

        <h2>Install on your Web Site</h2>
        <p>This is a <strong>PHP</strong> and <strong>MySQL</strong> script that you can install on your web site and hence do not pay any third party costs except PayPal fees.</p>
        <p>Your site requires PHP 7 and MySQL running on a Linux type web server.  Windows server can also be used.</p>
        <p><a class="btn btn-secondary" routerLink="/requirements" role="button">Requirements &raquo;</a></p>
      </div><!-- /.col-lg-4 -->
      <div class="col-lg-4">

        <fa-icon [icon]="faListAlt" size="4x" style="color:lightgray"></fa-icon>

        <h2>Responsive Layouts</h2>
        <p>Choose from a number of template driven layouts that use Bootstrap 4 and hence will work well on desk top displays, tablets and other mobile devices.</p>
        <p>Buy now and Add to cart buttons may also be added by hand to existing web sites with a customised cart.</p>
        <p><a class="btn btn-secondary" routerLink="/layouts" role="button">Layouts &raquo;</a></p>
      </div><!-- /.col-lg-4 -->
    </div><!-- /.row -->
    <!-- START THE FEATURETTES -->

    <hr class="featurette-divider">

    <div class="row featurette">
      <div class="col-md-7">
        <h2 class="featurette-heading">Security of the digital downloads.</h2>
        <p class="lead">
          There are a number of features that ensure your digital downloads are protected and cannot be downloaded unless they have been paid
          for.  The files can only be downloaded after a valid PayPal IPN has been received, they can only be downloaded a set number of times
          and within a time period, and they are normally placed out side the root of the web site so that they cannot be accessed directly or
          are protected by an htaccess file.
        </p>
      </div>
      <div class="col-md-5">
        <img class="img-fluid" alt="PHP Shopping Cart" src="/assets/img/close-up-connection-device_450x450.jpg" />
      </div>
    </div>

    <hr class="featurette-divider">

    <div class="row featurette">
      <div class="col-md-7 order-md-2">
        <h2 class="featurette-heading">Extensive Admin Interface.</h2>
        <p class="lead">
          The admin interface provides the facilites to create products, test the IPN system, define the buttons
          and other tasks.  All the set up requirements are entered through the admin pages such as information
          associated with your PayPal account, PayPal currency and the email text which is sent to the customer.
          You can also list the transactions and view graphs representing your sales.
        </p>
      </div>
      <div class="col-md-5 order-md-1">
        <img class="img-fluid" alt="PHP Shopping Cart" src="/assets/img/buttons-computer-keyboard-device_450x450.jpg" />
      </div>
    </div>

    <hr class="featurette-divider">

    <div class="row featurette">
      <div class="col-md-7">
        <h2 class="featurette-heading">Shipping for international destinations.</h2>
        <p class="lead">
          Shipping for physical goods can be either a fixed amount for all destinations or a different amount depending on the location of the user, or
          you may define no shipping at all for certain goods.  The method allows maximum flexibility in creating shipping allowing you to support purchases
          to different locations.
        </p>
      </div>
      <div class="col-md-5">
        <img class="img-fluid" alt="PHP Shopping Cart" src="/assets/img/cargo-containers-crate-export_450x450.jpg" />
      </div>
    </div>

    <hr class="featurette-divider">

    <div class="row featurette">
      <div class="col-md-7 order-md-2">
        <h2 class="featurette-heading">PayPal IPN System.</h2>
        <p class="lead">
          The distribution of the purchased digital downloads is controlled using the PayPal IPN (Instant Payment Notification) system.  After a purchase
          has been made, PayPal posts data back to the web site where it is checked and if it passes security tests, then the web site will automaitcally
          process the order.  In this case it will send an email to the customer with a download link.
        </p>
      </div>
      <div class="col-md-5 order-md-1">
        <img class="img-fluid" alt="PHP Shopping Cart" src="/assets/img/black-and-white-blurred-background-desk_450x450.jpg" />
      </div>
    </div>

    <hr class="featurette-divider">

    <div class="row featurette">
      <div class="col-md-7">
        <h2 class="featurette-heading">Documentation.</h2>
        <p class="lead">
          For full documentation on <strong>PHP-eSeller</strong>, refer to the <strong><a href="https://www.withinweb.com/phpeseller/" target="_blank">WithinWeb.com</a></strong> web site.  Products that are described on the web site include:
        </p>
        <ul>
          <li><strong><a href="https://www.withinweb.com/phpeseller/" target="_blank">PHP-eSeller</a></strong> - shopping cart system for selling digital and physical items.</li>
          <li><strong><a href="https://www.withinweb.com/phpkeycodes/" target="_blank">PHP-KeyCodes</a></strong> - automatically sell license codes, pin codes or other key codes.</li>
          <li><strong><a href="https://www.withinweb.com/phpsecurearea/" target="_blank">PHP-SecureArea</a></strong> - secure an area of a web site usign PayPal subscriptions.</li>
        </ul>
      </div>
      <div class="col-md-5">
        <img class="img-fluid" alt="PHP Shopping Cart" src="/assets/img/books-document-education_450x450.jpg" />
      </div>
    </div>

    <hr class="featurette-divider">

    <!-- /END THE FEATURETTES -->

  </div><!-- /.container -->
