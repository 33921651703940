import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {

  constructor(public meta: Meta, public title: Title) {
  }

  ngOnInit() {
    const pageTitle: string = 'Sell Digital Downloads and Physical Items with eCommerce PHP Shopping Cart';

    this.meta.addTag({
      name: 'description',
      content: 'Sell physical items and sell digital downloads using an ecommerce PHP shopping cart on your web site with PayPal'
    });
    this.title.setTitle(pageTitle);
  }

}
