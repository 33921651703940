<div class="container marketing">

  <div class="row">
    <div class="col-lg-2"></div>
    <div class="col-lg-8">
      <h1 style="text-align:center;">PHP-eSeller<br />Pricing</h1>
    </div>
    <div class="col-lg-2"></div>
  </div>

  <hr class="featurette-divider">

  <div class="card-deck mb-3 text-center">
    <div class="card mb-4 shadow-sm">
      <div class="card-header">
        <h4 class="my-0 font-weight-normal">PHP-eSeller</h4>
      </div>
      <div class="card-body">
        <h1 class="card-title pricing-card-title">$55</h1>
        <ul class="list-unstyled mt-3 mb-4">
          <li></li>
        </ul>
        <a href="https://www.withinweb.com/phpeseller/purchase.php" target="_blank" class="btn btn-lg btn-block btn-primary">How to Purchase</a>
      </div>
    </div>
    <div class="card mb-4 shadow-sm">
      <div class="card-header">
        <h4 class="my-0 font-weight-normal">PHP-eSeller Including Installation</h4>
      </div>
      <div class="card-body">
        <h1 class="card-title pricing-card-title">$85</h1>
        <ul class="list-unstyled mt-3 mb-4">
          <li></li>
        </ul>
        <a href="https://www.withinweb.com/phpeseller/purchase.php" target="_blank" class="btn btn-lg btn-block btn-primary">How to Purchase</a>
      </div>
    </div>
  </div>

</div>
