<div class="container marketing">

  <div class="row">
    <div class="col-lg-2"></div>
    <div class="col-lg-8">
      <h1 style="text-align:center;">PHP-eSeller<br />Requirements</h1>
    </div>
    <div class="col-lg-2"></div>
  </div>

  <hr class="featurette-divider">


  <div class="row">
    <div class="col-lg-2">&nbsp;</div><!-- /.col-lg-2 -->
    <div class="col-lg-8">
      <div class="lead">
        <ul>
          <li>Targeted for <strong>PHP 7</strong> and above, but will also work with PHP 5.6 and above.</li>
          <li>Apache web server or Windows IIS</li>
          <li>An SSL certificate on your web server to use https for communications with PayPal</li>
          <li><strong>MySQL</strong> database version 5 or higher</li>
          <li>Linux, FreeBSD or Windows operating system on the web server</li>
          <li>
            A Paypal Business or Premier account (if using PayPal) sign up
            <a href="https://www.paypal.com/uk/mrb/pal=L9C6XEDHCDXQ2" target="_blank">here</a>.
          </li>
          <li>Email functionality on the web server so that the PHP scripts can send out emails to customers.</li>
          <li>
            Most web hosts now have <strong>PHP</strong> and <strong>MySQL</strong> as standard so you should not have any issue in finding
            a basic web hosting plan.  We have found web hosts such as <strong>siteground.com</strong> and <strong>lunarpages.com</strong>
            to meet requirements.  Email us if you require advice.
          </li>
        </ul>
      </div>
    </div><!-- /.col-lg-8 -->
    <div class="col-lg-2">&nbsp;</div><!-- /.col-lg-2 -->
  </div><!-- /.row -->


</div>

