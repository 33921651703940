<div class="container marketing">

  <div class="row">
    <div class="col-lg-2"></div>
    <div class="col-lg-8">
      <h1 style="text-align:center;">PHP-eSeller<br />Layouts</h1>
    </div>
    <div class="col-lg-2"></div>
  </div>



  <div class="row featurette">
    <div class="col-md-7">
      <h2 class="featurette-heading">Datatable with cart - no formatting.</h2>
      <p class="lead">
        This uses the JQuery Datatable system to display a row layout and includes sorting, searching and paging as
        standard. The datatable display is good for long listings of audio products and works well on all devices including mobiles.
      </p>
      <p><a href="https://www.withinweb.com/demoeseller2/cart/" class="btn btn-secondary" role="button" target="_blank">View &raquo;</a></p>
    </div>
    <div class="col-md-5">
      <img class="img-fluid" alt="PHP Shopping Cart" src="/assets/img/cart_no_formatting_450x450.jpg" />
    </div>
  </div>



  <div class="row featurette">
    <div class="col-md-7">
      <h2 class="featurette-heading">Datatable with cart - using Bootstrap layout.</h2>
      <p class="lead">
        This is similar to the above but has Boostrap layout wrapped around the Datatable.  Bootstrap
        is suitable for displaying on all devices including mobiles.
      </p>
      <p><a href="https://www.withinweb.com/demoeseller2/shop/" class="btn btn-secondary" role="button" target="_blank">View &raquo;</a></p>
    </div>
    <div class="col-md-5">
      <img class="img-fluid" alt="PHP Shopping Cart" src="/assets/img/cart_bootstrap_450x450.jpg" />
    </div>
  </div>


  <div class="row featurette">
    <div class="col-md-7">
      <h2 class="featurette-heading">Multi-column display - using Boostrap layout.</h2>
      <p class="lead">
        This layout implements a cart system using Bootstrap.  It uses an add to cart button for each
        item and further details of each item can be displayed.  All displays are fully responsive and work well on all devices including
        mobiles.
      </p>
      <p><a href="https://www.withinweb.com/demoeseller2/store/" class="btn btn-secondary" role="button" target="_blank">View &raquo;</a></p>
    </div>
    <div class="col-md-5">
      <img class="img-fluid" alt="PHP Shopping Cart" src="/assets/img/cart_muti_column_bootstrap_450x450.jpg" />
    </div>
  </div>


  <div class="row featurette">
    <div class="col-md-7">
      <h2 class="featurette-heading">Ajax style store front layout.</h2>
      <p class="lead">
        This is an implementation of a single page product display including a cart and check out facility.  The
        display provides an excellent user experience for situations where you have a large number of audio products to show on a
        compact page.
      </p>
      <p><a href="https://www.withinweb.com/demoecart/front/" class="btn btn-secondary" role="button" target="_blank">View &raquo;</a></p>
    </div>
    <div class="col-md-5">
      <img class="img-fluid" alt="PHP Shopping Cart" src="/assets/img/ajax_cart_450x450.jpg" />
    </div>
  </div>

  <div class="row featurette">
    <div class="col-md-7">
      <h2 class="featurette-heading">Music Album display.</h2>
      <p class="lead">
        This layout can be used to sell albums and separate audio files presenting them in their album structures.  The layout will be useful
        when you have a number of albums to sell.
      </p>
      <p><a href="https://www.withinweb.com/demoeseller2/albums/" class="btn btn-secondary" role="button" target="_blank">View &raquo;</a></p>
    </div>
    <div class="col-md-5">
      <img class="img-fluid" alt="PHP Shopping Cart" src="/assets/img/cart_music_450x450.jpg" />
    </div>
  </div>


  <div class="row featurette">
    <div class="col-md-7">
      <h2 class="featurette-heading">Buy now and add to cart buttons added manually.</h2>
      <p class="lead">
        Finally, you can add buttons by hand to existing web pages so you can integrate into an existing layout.  This is particularly useful
        if you only have a few items to sell and do not want to create lisings of products.
      </p>
      <p><a href="https://www.withinweb.com/demoeseller2/basket/examples.php" class="btn btn-secondary" role="button" target="_blank">View &raquo;</a></p>
    </div>
    <div class="col-md-5">
      <img class="img-fluid" alt="PHP Shopping Cart" src="/assets/img/cart_basket_450x450.jpg" />
    </div>
  </div>


</div>
