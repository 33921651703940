import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  providers: [DatePipe]
})
export class FooterComponent implements OnInit {
  year: string;

  constructor(private datePipe: DatePipe) { }

  ngOnInit() {
  }

  currentYear() {
    this.year = this.datePipe.transform(new Date(), "yyyy");
    return this.year;
  }

  backToTop() {
    window.scrollTo(0, 0);
  }

}
