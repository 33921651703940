import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  statusClick: boolean = false;

  constructor() { }

  ngOnInit() {
  }
  
  onClick() {
    //set  id="navbarCollapse" style to add 'show'
    //and set button class="navbar-togglar collapsed" to class="navbar-togglar"

    if (this.statusClick === false) {
      let element = document.getElementById('navbarCollapse');
      element.className = 'navbar-collapse collapse show'
    
      //let elementBtn = document.getElementById('navBarClick');
      //elementBtn.className = 'navbar-toggler'
      
      this.statusClick = true;
    }
    else
    {
      let element = document.getElementById('navbarCollapse');
      element.className = 'navbar-collapse collapse'

      //let elementBtn = document.getElementById('navBarClick');
      //elementBtn.className = 'navbar-togglar collapsed'

      this.statusClick = false;
    }
    
  }

  emailAddress() {
    return "admin@withinweb.com";
  }

}


/*
<button class="navbar-togglar collapsed" data-toggle="collapse" aria-expanded="false"
=>
<button class="navbar-togglar" data-toggle="collapse" aria-expanded="true"


<div class="navbar-collapse collapse" id="navbarCollapse"
=>
<div class="navbar-collapse collapse show" id="navbarCollapse"


Possible answer:
================
https://stackoverflow.com/questions/44535515/angular-ngclass-and-click-event-for-toggling-class
*/
