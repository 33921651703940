<nav class="navbar navbar-expand-md navbar-light fixed-top bg-light">
  <a class="navbar-brand" routerLink="/home">PHP-eSeller</a>
  <button class="navbar-toggler"
          id="navBarClick"
          (click)="onClick()"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarCollapse">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/home">Home</a>
      </li>  
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/pricing">Pricing</a>
      </li>     
    </ul>
    <strong>{{ emailAddress() }}</strong>
  </div>
</nav>
