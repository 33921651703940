import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-requirements',
  templateUrl: './requirements.component.html',
  styleUrls: ['./requirements.component.css']
})
export class RequirementsComponent implements OnInit {

  constructor(public meta: Meta, public title: Title) {
  }

  ngOnInit() {
    const pageTitle: string = 'Construct buy now and add to cart buttons for PHP shopping cart digital downloads';

    this.meta.addTag({
      name: 'description',
      content: 'How to sell digital downloads with buy now and add to PHP shopping cart buttons'
    });
    this.title.setTitle(pageTitle);
  }

}
