import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  constructor(public meta: Meta, public title: Title) {
  }

  ngOnInit() {
    const pageTitle: string = 'Sell digital downloads and physical products with PayPal ecommerce shopping PHP cart';

    this.meta.addTag({
      name: 'description',
      content: 'Features of PHP-eSeller for selling mp3 digital downloads and physical goods using PHP shopping cart with PayPal'
    });
    this.title.setTitle(pageTitle);
  }

}
