import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { filter } from 'rxjs/operators';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { FeaturesComponent } from './features/features.component';
import { RequirementsComponent } from './requirements/requirements.component';
import { LayoutsComponent } from './layouts/layouts.component';
import { PricingComponent } from './pricing/pricing.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Router, NavigationEnd } from '@angular/router';

import { LocationStrategy, PathLocationStrategy } from '@angular/common';

declare var gtag;

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    FeaturesComponent,
    RequirementsComponent,
    LayoutsComponent,
    PricingComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FontAwesomeModule
  ],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(router: Router) {
    const navEndEvents = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    );
    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag('config', 'UA-1290247-6', {
        'path_page': event.urlAfterRedirects
      });
    })
      
  }
}
